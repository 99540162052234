body {
  background-color: #f8f9fa;
  font-family: "Titillium Web", sans-serif; }

.navbar {
  background-color: #fff;
  border-bottom-color: #e0e5e9;
  border-width: 0 0 2px;
  border-style: solid;
  height: 60px; }
  .navbar h1 {
    font-size: 40px;
    font-weight: 600; }
  .navbar .navbar-nav {
    font-weight: 600;
    font-size: 20px; }

.search {
  position: relative;
  height: 500px; }
  .search .title {
    color: #404969;
    font-size: 45px;
    font-weight: 700;
    margin-top: 130px;
    margin-left: 80px; }
  .search .text-orange {
    color: #dc552c; }
  .search .search-bg {
    background: url("../../images/Brennatocat.png");
    background-size: cover;
    background-size: auto 650px;
    background-repeat: no-repeat;
    background-position: -60px -70px; }
  .search .search-form {
    position: absolute;
    top: 270px; }
    .search .search-form .search-input {
      height: 50px;
      border-radius: 0px; }
  .search .dropdown-search .dropdown-menu {
    border-radius: 0px; }
  .search .dropdown-search .dropdown-toggle {
    background-color: #fff;
    border-color: #8d9296;
    color: #404969; }
  .search .btn-search {
    color: #f8f9fa;
    background-color: #dc552c; }
  .search .btn-clear {
    color: #f8f9fa;
    background-color: #404969; }

@media only screen and (max-width: 991px) {
  .search .title {
    font-size: 34px; } }
.scubatocat {
  height: 400px;
  position: relative;
  top: -220px;
  left: 30px;
  z-index: -10; }

.user-card {
  border-radius: 0px;
  border-width: 0px;
  box-shadow: -5px 10px 60px #e0e5e9; }
  .user-card .score {
    color: #6c6e6e; }
  .user-card .btn {
    background-color: #404969; }

.repo-owner {
  background-color: #fff;
  border-radius: 0px;
  border-width: 0px;
  border-top-color: #dda08e;
  border-top-width: 3px;
  box-shadow: -5px 10px 60px #e0e5e9; }
  .repo-owner .text-primary {
    font-size: 14px;
    font-weight: 600; }

.profile {
  background-color: rgba(64, 73, 105, 0.9);
  color: #f8f9fa;
  min-height: 350px; }
  .profile .bg-image {
    position: absolute;
    z-index: -10;
    overflow: hidden; }
    .profile .bg-image img {
      height: 550px;
      align-self: auto;
      position: relative;
      top: -140px; }
  .profile .btn-visit {
    background-color: rgba(220, 85, 44, 0.8);
    color: #f8f9fa; }

.userBadge {
  border-radius: 0px;
  background-color: #fff;
  box-shadow: -5px 10px 60px #e0e5e9; }
  .userBadge .badge {
    padding: 5px 10px;
    font-size: 12px;
    color: #f8f9fa;
    border-radius: 1px; }

.repoOrder h4 {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0px; }
.repoOrder .dropdown .dropdown-menu {
  border-radius: 0px; }

.repo-card {
  border-radius: 0px;
  border-width: 0px;
  box-shadow: -5px 10px 60px #e0e5e9;
  height: 200px;
  overflow: hidden; }
  .repo-card h5 {
    height: 24px;
    font-weight: 600;
    overflow: hidden; }
  .repo-card .language {
    font-weight: 600;
    color: #404969;
    font-size: 15px; }
  .repo-card .repoDescription {
    height: 80px;
    overflow: hidden; }
  .repo-card .repoLable {
    color: #6c757d;
    font-weight: 600;
    font-size: 14px;
    fill: #6c757d; }
